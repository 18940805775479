"use client";

import { useGlobalStore } from "@/app/context/globalStoreProvider";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import ThumbsDown from "@/components/ui/svgComponents/ThumbsDown";
import ThumbsUp from "@/components/ui/svgComponents/ThumbsUp";
import {
  UsersUserIdBookingsBookingIdRatePastStayPutRequest,
  UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest,
} from "@/generated/email_parser.openapi";
import {
  cn,
  getRating,
  screenInnerHeight,
  smallHeightBreakPoint,
} from "@/lib/utils";
import { DialogProps } from "@radix-ui/react-dialog";
import { useSession } from "next-auth/react";
import React from "react";
import { toast } from "react-toastify";

const RatePastBookingModal: React.FC<DialogProps> = (props) => {
  const {
    welcomeActions: { data, refetchWelcomeActions },
  } = useGlobalStore();
  const { data: session } = useSession();

  const bookingData = data?.ratePastStayWelcomeActions?.items[0];

  const onRating = (rating: "up" | "down") => {
    const request: UsersUserIdBookingsBookingIdRatePastStayPutRequest = {
      bookingId: Number(bookingData?.bookingId),
      ratePastStayRequest: {
        stayHereAgain: rating === "up",
      },
      userId: session?.user.id as string,
    };
    (async () => {
      await fetch("/api/user/welcome_actions/rate_past_stay", {
        method: "POST",
        body: JSON.stringify(request),
      });
      await deleteWelcomeAction();
      await refetchWelcomeActions();
    })();
    toast.success("Thank you for your feedback!");
    props.onOpenChange?.(false);
  };

  const onDismiss = () => {
    deleteWelcomeAction();
    props.onOpenChange?.(false);
  };

  const deleteWelcomeAction = async () => {
    if (bookingData?.id) {
      const request: UsersUserIdWelcomeActionsWelcomeActionIdDeleteRequest = {
        userId: session?.user.id as string,
        welcomeActionId: bookingData.id,
      };
      await fetch("/api/user/welcome_actions/delete", {
        method: "POST",
        body: JSON.stringify(request),
      });
    }
  };

  if (!bookingData) {
    return null;
  }

  const isSmallScreen = screenInnerHeight < smallHeightBreakPoint;

  return (
    <Dialog {...props} open={props.open} onOpenChange={onDismiss}>
      <DialogContent
        className={cn(
          "z-[100] w-full max-w-[740px]",
          isSmallScreen ? "h-[620px] overflow-auto" : ""
        )}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-lg lg:text-[32px]">
            {`Would you stay at the ${bookingData?.propertyName} hotel again?`}
          </DialogTitle>
        </DialogHeader>
        <div className="text-center text-sm font-normal leading-snug text-primary text-opacity-50 lg:text-base">
          Your feedback will help us to better recommend hotels for you in the
          future
        </div>
        <div className="flex items-center justify-center">
          <img
            className={cn(
              "w-full rounded-xl border border-white/10 object-cover",
              isSmallScreen ? "h-[200px]" : "h-[283px]"
            )}
            src={bookingData?.heroImage}
            alt=""
          />
        </div>
        <div className="flex w-full items-center justify-between gap-2 text-lg font-normal text-white lg:text-2xl">
          {bookingData?.propertyName}
          <div className="flex items-center gap-1 text-sm lg:text-base">
            <img
              src="/assets/star.svg"
              alt="Star"
              className="inline h-3 w-3 lg:h-4 lg:w-4"
            />
            {bookingData?.averageGuestRating
              ? getRating(bookingData.averageGuestRating)
              : ""}
          </div>
        </div>
        <div className="flex items-center justify-start gap-1.5">
          {bookingData?.chainName && (
            <div className="border-r border-opacity-30 pr-2 text-sm font-medium text-light text-opacity-65 lg:text-base">
              {bookingData?.chainName}
            </div>
          )}
          <div className="shrink grow basis-0 text-sm font-normal leading-tight text-light text-opacity-65 lg:text-base">
            {bookingData?.propertyAddress}
          </div>
        </div>
        <DialogFooter className="mt-2">
          <div className="flex h-[65px] items-center justify-start gap-4 rounded-[57px] border border-[#3d3f43] bg-white/10 p-3 shadow backdrop-blur-[32.20px] lg:h-[100px] lg:gap-6">
            <div
              className="flex cursor-pointer items-center justify-start gap-2.5 rounded-full border-2 border-white bg-white/20 p-3 lg:p-5"
              onClick={() => {
                onRating("up");
              }}
            >
              <ThumbsUp className="relative h-5 w-5 lg:h-9 lg:w-9" />
            </div>
            <div
              className="flex cursor-pointer items-center justify-start gap-2.5 rounded-full border-2 border-white bg-white/20 p-3 lg:p-5"
              onClick={() => {
                onRating("down");
              }}
            >
              <ThumbsDown className="relative h-5 w-5 lg:h-9 lg:w-9" />
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RatePastBookingModal;
