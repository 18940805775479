import * as React from "react";
import { SVGProps } from "react";

const ThumbsDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    {...props}
  >
    <path
      stroke="#FDFBFB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.25}
      d="M11.247 22.875H6.558c-1.54 0-2.918-1.041-3.081-2.572a18.204 18.204 0 0 1-.102-1.928c0-4.271 1.488-8.195 3.973-11.282C7.93 6.371 8.828 6 9.756 6h6.024a6.75 6.75 0 0 1 2.134.346l4.672 1.558a6.75 6.75 0 0 0 2.134.346h1.942M11.247 22.875c.928 0 1.486 1.086 1.088 1.923a11.205 11.205 0 0 0-1.085 4.827A3.375 3.375 0 0 0 14.625 33c.621 0 1.125-.504 1.125-1.125v-.95c0-.859.164-1.71.483-2.507.456-1.14 1.395-1.996 2.479-2.573 1.67-.89 3.132-2.12 4.292-3.6.746-.951 1.838-1.62 3.047-1.62h.576m-15.38 2.25h3.303M26.662 8.25c.016.075.041.15.077.222a13.445 13.445 0 0 1 1.386 5.966c0 2.23-.54 4.333-1.498 6.187m.035-12.375c-.116-.547.274-1.125.862-1.125h1.362c1.333 0 2.57.777 2.958 2.052.508 1.664.781 3.43.781 5.26 0 2.33-.442 4.555-1.247 6.597-.458 1.161-1.629 1.841-2.877 1.841h-1.58c-.707 0-1.117-.834-.75-1.439.161-.264.313-.535.456-.811"
    />
  </svg>
);

export default ThumbsDown;
